<template>
    <modal ref="modalEnviarEncuesta" titulo="Cambiar fecha de vigencia" guardar tamano="modal-md" adicional="Guardar" @adicional="guardarFecha">
        <ValidationObserver tag="div">
            <div class="row justify-content-center mx-0 py-3">
                <ValidationProvider tag="div">
                    <p class="f-12 pl-2 label-inputs text-left">Día de inicio</p>
                    <el-date-picker v-model="fechaDiaInicio" type="date" format="dd [de] MMMM yyyy" value-format="yyyy-MM-dd" :picker-options="pickerOptions" placeholder="Seleccionar" size="small" />
                </ValidationProvider>
            </div>
            <div class="row justify-content-center mx-0 py-3">
                <ValidationProvider tag="div">
                    <p class="f-12 pl-2 label-inputs text-left">Día de finalización</p>
                    <el-date-picker v-model="fechaDiaFin" type="date" format="dd [de] MMMM yyyy" value-format="yyyy-MM-dd" :picker-options="pickerOptions" placeholder="Seleccionar" size="small" />
                </ValidationProvider>
            </div>
        </ValidationObserver>
    </modal>
</template>
<script>
import moment from 'moment'
import Encuestas from '~/services/encuestas'
export default {
    data(){
        return{
            fechaDiaInicio:'',
            fechaDiaFin:'',
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < moment().subtract(1,'days')
                },
            }
        }
    },
    methods:{
        toggle(fechas){
            if(!_.isEmpty(fechas)){
                this.fechaDiaInicio = fechas.fecha_inicio
                this.fechaDiaFin = fechas.fecha_fin
            }

            this.$refs.modalEnviarEncuesta.toggle()
        },
        guardarValores(){
            this.$refs.modalEnviarEncuesta.toggle()
        },
        async guardarFecha(){
            try {

                if(!this.fechaDiaInicio || !this.fechaDiaFin) return this.notificacion('','Por favor selecciona una fecha valida')

                let params = {
                    id_encuesta: this.$route.params.id_encuesta,
                    fecha_inicio: this.fechaDiaInicio,
                    fecha_fin: this.fechaDiaFin
                }
                const { data } = await Encuestas.editarFechaVigencia(params)

                this.notificacion('','Vigencia actualizada correctamente','success')
                this.$emit('actualizar')
                this.$refs.modalEnviarEncuesta.toggle()

            } catch (error) {
                this.errorCatch(error)
            }
        }
    }
}
</script>
<style lang="scss" scoped>

</style>